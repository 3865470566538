import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import "../styles/_hero.scss"

const Hero = () => (
  <Container fluid className="landing-hero px-0">
    <div className="wedge">
      <Container fluid="xl" className="px-4 px-md-5">
        <Row>
          <Col className="cols" sm={{ span: 6 }}>
            <div className="hero-title">
              <div className="line-1 fw-600">Lead Engagement Software</div>
              <h1 className="lg f-48 fw-700">Never Miss a <br/>Real Estate Lead</h1>
              <h2 className="subheading">
                SpaceIt captures and tracks leads, so you don't have to worry
                about losing a lead, ever.
              </h2>
              <div>
                <Button
                  variant="primary"
                  size="lg"
                  className="button__cta f-18 fw-500"
                  href="#contact"
                >
                  Get a Free Demo
                </Button>
              </div>
            </div>
          </Col>
          <Col className="cols hero-img" sm={{ span: 6 }}></Col>
        </Row>
      </Container>
    </div>
  </Container>
)

export default Hero
