import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import ContactForm from "./form"

const Contact = () => {
  return (
    <section className="section-contact py-5">
      <Container>
        <Row className="justify-content-center">
          <Col sm={{ span: 4 }} md={{ span: 4 }} className="">
            <h1 className="f-36 fw-700">Contact</h1>
            {/* <div className="pb-2">
              To request a SpaceIt demo with our team, please provide your
              contact details.
            </div> */}
            <div className="my-3">
              <div>New York</div>
              <div>900 Broadway</div>
              <div>Suite 905</div>
              <div>New York, NY 10010</div>
              <div>646-687-7717</div>
              <div>info@spaceit.com</div>
            </div>
            <div className="my-3">
              <div>Miami</div>
              <div>9010 SW 68th Avenue</div>
              <div>Miami FL 33156</div>
            </div>
          </Col>
          <Col sm={{ span: 8 }} md={{ span: 6 }}>
            <div id="contact" className="contact-outer p-4">
              <h1 className="f-24 fw-600 my-3">Request a Demo</h1>
              <div className="pb-2">
                We're happy to answer questions and get you acquainted with our
                product.
              </div>
              <ContactForm></ContactForm>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
