import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import magent from "../images/magnet.png"
import magnet2x from "../images/magnet@2x.png"
import leads from "../images/leads.png"
import leads2x from "../images/leads@2x.png"
import reports from "../images/reports.png"
import reports2x from "../images/reports@2x.png"

const Overview = () => {
  return (
    <div className="overview my-4">
      <Container className="text-center">
        <Row className="justify-content-center">
          <Col md="10">
            <div className="line-1 fw-600">How It Works</div>
            <h1 className="f-34 fw-700">Built For Real Estate Professionals</h1>
          </Col>
        </Row>
        <Row className="justify-content-center py-3">
          <Col sm="12" md="4" lg="3" className="">
            <div className="capsule">
              <div>
                <img
                  className="capsule_icon"
                  srcSet={`${magent}, ${magnet2x} 2x`}
                  src={magnet2x}
                  alt="Icon of a magnet."
                ></img>
              </div>
              <h2 className="f-18 fw-700 mt-2">Automated Lead Capture</h2>
              <p>
                Set-up marketing campaigns across phone, text, and email. Track
                leads across multiple sources, such as social, web, print and
                signage.
              </p>
            </div>
          </Col>
          <Col sm="12" md="4" lg="3">
            <div className="capsule">
              <img
                className="capsule_icon"
                srcSet={`${leads}, ${leads2x} 2x`}
                src={leads2x}
                alt="Icon of leads."
              ></img>
              <h2 className="f-18 fw-700 mt-2">Custom Lead Routing</h2>
              <p>
                Want to push high value leads to your best performing agents?
                With SpaceIt, you decide how leads are routed, assigned or
                claimed.
              </p>
            </div>
          </Col>
          <Col sm="12" md="4" lg="3">
            <div className="capsule">
              <img
                className="capsule_icon"
                srcSet={`${reports}, ${reports2x} 2x`}
                src={reports2x}
                alt="Icon of a report."
              ></img>
              <h2 className="f-18 fw-700 mt-2">Track Results</h2>
              <p>
                View and download campaign reports. Understand how quickly leads
                are being claimed or assigned.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
{/* 
      <Container fluid="lg" className="px-5">
        <Row className="justify-content-center">
          <Col className="cols" sm={8} md={{ span: 5 }}>
            <div className="sticky">
              <div className="line-1 fw-600">Text</div>
              <h1 className="f-36 fw-700">
                Heading
              </h1>
              <div>
                Text
              </div>
            </div>
          </Col>
          <Col className="cols" sm={8} md={{ span: 5, offset: 1 }}>
            <div className="section-text">
              <div className="list">
                <h1 className="f-36 fw-700">
                  Automated, Cross-Channel Lead Capture
                </h1>
                <div>
                  Set-up marketing campaigns across one or more channels to
                  automatically track leads and sources.
                </div>

                <div className="section-image">
                  <img />
                </div>
              </div>

              <div className="list">
                <h1 className="f-36 fw-700">Customized Lead Routing</h1>
                <div>
                  Want to push high value leads to your best performing agents?
                  With SpaceIt, you decide how leads are rouuted, assigned or
                  automatically claimed.
                </div>

                <div className="section-image">
                  <img />
                </div>
              </div>

              <div className="list">
                <h1 className="f-36 fw-700">Track Results</h1>
                <div>
                  Access all inbound call recordings, transcripts, voicemail,
                  texts, email and online forms. Understand how quickly leads
                  are being claimed or assigned. View and download reports on
                  call activity and campaign performance.
                </div>

                <div className="section-image">
                  <img />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
    </div>
  )
}

export default Overview
