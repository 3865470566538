import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Benefits from "../components/benefits"
import Promo from "../components/promo"
import Overview from "../components/overview"
import Contact from "../components/contact"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "SpaceIt" }} showFeature hero={<Hero />}>
    <SEO
      title="Real Estate Lead Engagement Software | SpaceIt"
      keywords={[
        `broker technology`,
        `commercial real estate`,
        `residential real estate`,
        `commercial real estate technology`,
        `residential real estate technology`,
        `real estate`,
        `spaceit`,
      ]}
    />
    <Promo />
    <Benefits />
    <Overview />
    <Contact />
  </Layout>
)

export default IndexPage
