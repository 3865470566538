import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import "../styles/_benefits.scss"
import safe from "../images/safe.png"
import safe2x from "../images/safe@2x.png"
import actions from "../images/actions.png"
import actions2x from "../images/actions@2x.png"
import omnichannel from "../images/omnichannel.png"
import omnichannel2x from "../images/omnichannel@2x.png"
import shield from "../images/shield.png"
import shield2x from "../images/shield@2x.png"

const Benefits = () => {
  return (
    <div data-id="slab" className="slab py-4">
      {/* <div className="test"></div> */}
      <Container fluid="lg" className="px-5">
        <Row className="align-items-center justify-content-center my-5">
          <Col className="cols" sm={8} md={{ span: 4, offset: 1 }}>
            <div className="tagline f-14 fw-500">Always On</div>
            <h1 className="f-36 fw-700">Omni-Channel Lead Capture</h1>
            <p className="my-2">
              Capture and qualify your leads from every marketing source, all in
              one place.
            </p>
          </Col>
          <Col className="cols text-center" sm={8} md={{ span: 6 }}>
            <img
              className="image_sm"
              srcSet={`${omnichannel}, ${omnichannel2x} 2x`}
              src={omnichannel2x}
              alt="Graphic showing the various marketing channels SpaceIt captures leads from."
            ></img>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center my-5">
          <Col className="cols" sm={8} md={{ span: 4, order: 1, offset: 1 }}>
            <div className="tagline f-14 fw-500">Speed</div>
            <h1 className="f-36 fw-700">Engage Faster</h1>
            <p className="my-2">
              Know which property leads are interested in, streamline your lead
              qualification process and respond via multiple channels.
            </p>
          </Col>
          <Col className="cols  text-center" sm={8} md={{ span: 6 }}>
            <img
              className="image_md"
              srcSet={`${actions}, ${actions2x} 2x`}
              src={actions2x}
              alt="Graphic showing real estate professional and actions to qualify a lead."
            ></img>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center  my-5">
          <Col className="cols" sm={8} md={{ span: 4, offset: 1 }}>
            <div className="tagline f-14 fw-500">
              Your Properties, Your Leads
            </div>
            <h1 className="f-36 fw-700">Own Your Data</h1>
            <p className="my-2">
              Collect granular data on leads, campaign activity and results,
              building a robust, searchable database of future prospects. Gain
              visibility into agent behavior and hold teams accountable in a
              single, secure location.
            </p>
          </Col>
          <Col className="cols  text-center" sm={8} md={{ span: 6 }}>
            <img
              className="image_sm"
              srcSet={`${shield}, ${shield2x} 2x`}
              src={shield2x}
              alt="Graphic representing your lead data."
            ></img>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Benefits
