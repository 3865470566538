import React from "react"
import { useFormik } from "formik"
import { Button, Form } from "react-bootstrap"

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      message: "",
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      await post(values)
        .then(response => {
          console.log(response)
          if (response.status !== 200) {
            throw new Error(
              `HTTP Response returning status code: ${response.status}`
            )
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmission",
            eventContext: "ContactForm",
            eventStatus: 'success',
            email: values.email,
            name: values.name,
          })
          alert("Message sent. The SpaceIt team will be in touch. Thank you.")
          resetForm()
        })
        .catch(e => {
          resetForm()
          console.log(e)
          window.dataLayer.push({
            event: "formSubmission",
            eventContext: "ContactForm",
            eventStatus: 'error',
            email: values.email,
            name: values.name,
          })
          alert(
            "There was a problem submitting the form. Please try again later"
          )
        })
    },
  })

  const post = async values => {
    return await _fetch(values)
  }

  const _fetch = values => {
    const url = `${process.env.UTIL_API}/demo-request`
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Control
          type="text"
          name="name"
          required
          placeholder="Name"
          onChange={formik.handleChange}
          value={formik.values.name}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          type="email"
          name="email"
          required
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          rows="3"
          required
          as="textarea"
          name="message"
          placeholder="Message"
          onChange={formik.handleChange}
          value={formik.values.message}
        />
      </Form.Group>
      <div className="text-center">
        <Button
          disabled={formik.isSubmitting}
          size="lg"
          variant="primary"
          type="submit"
          className="button__submit fw-500"
        >
          Request Demo
        </Button>
      </div>
    </Form>
  )
}

export default ContactForm
