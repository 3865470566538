import React from "react";
import '../styles/_promo.scss';
import { Row, Col, Container } from "react-bootstrap";

const Promo = () => {
  return (
    <Container className="text-center promo">
      <Row className="justify-content-center">
        <Col md="10">
          <div className="line-1 fw-600">Lost Opportunities</div>
          <h3 className="f-24 heading">
            If you do not respond to a lead in less than five minutes, 
            the chances of converting decrease by 400%
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center py-3">
        <Col sm="12" md="4" lg="3" className="">
          <div className="infographic radial50">
            <div className="percentage">
              50%
            </div>
            <div>
              of opportunities are lost due to missed calls
            </div>
          </div>
        </Col>
        <Col sm="12" md="4" lg="3">
          <div className="infographic radial85">
            <div className="percentage">
              85%
            </div>
            <div>
              of leads whose calls go unanswered will not call back
            </div>
          </div>
        </Col>
        <Col sm="12" md="4"lg="3">
          <div className="infographic radial41">
            <div className="percentage">
              41%
            </div>
            <div>
              of brokers don't reply to inquiries
            </div>
          </div>
        </Col>
      </Row>
  </Container>
  );
};

export default Promo;